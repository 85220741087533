/* eslint-disable import/prefer-default-export */
import moment from 'moment';

// api default data
export const DEF_SEARCH_PARAM = {
  vins: [],
  vehicleIds: [],
  fromTsInIST: moment(new Date())
    .subtract(1, 'day')
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss'),
  toTsInIST: moment(new Date())
    .subtract(1, 'day')
    .endOf('day')
    .format('YYYY-MM-DD HH:mm:ss'),
  groupNames: [],
};

export const reportNames = [
  {
    id: 1,
    name: 'Vehicle Summary',
    description: 'Vehicle Summary is enabled by default and cannot be disabled',
  },
  {
    id: 2,
    name: 'Vehicle Instant',
    description: 'Vehicle Instant is enabled by default and cannot be disabled',
  },
  {
    id: 3,
    name: 'Autotrip Summary',
    alias: ['autoTripReportEnabled', 'hubToHubReportEnabled'],
    description:
      'If Autotrip reports are required please enable it. Report will be generated from that day onwards.',
  },
  {
    id: 4,
    name: 'Hub to Hub',
    alias: ['hubToHubReportEnabled', 'autoTripReportEnabled'],
    description:
      'If Hub to Hub reports are required please enable it. Report will be generated from that day onwards.',
  },
];

export const reportNameMapper = {
  'VEHICLE SUMMARY': 'VEHICLE_SUMMARY',
  'HUB TO HUB': 'SUMMARIZE_HUB2HUB_REPORT_SUMMARY',
  'AUTOTRIP SUMMARY': 'AUTO_TRIP_SUMMARY',
  'VEHICLE INSTANT': 'VEHICLE_INSTANT',
};

export const VehicleSummaryTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  {
    name: 'Starting Odometer',
    id: 'StartODO',
    sort: true,
    unitConversion: '(kms)',
  },
  {
    name: 'End Odometer',
    id: 'EndODO',
    sort: true,
    unitConversion: '(kms)',
  },
  {
    name: 'Distance Travelled',
    id: 'DistanceTraveled',
    sort: true,
    unitConversion: '(kms)',
    minWidth: '170px',
  },
  {
    name: 'Fuel Consumed',
    id: 'FuelConsumed',
    sort: true,
    unitConversion: '(ltrs)',
    minWidth: '160px',
  },
  // {
  //   name: 'Adblue Consumed',
  //   id: 'AdblueConsumed',
  //   sort: true,
  //   unitConversion: '(ltrs)',
  //   minWidth: '175px',
  // },
  // {
  //   name: 'Adblue (%)',
  //   id: 'adlPercentage',
  //   isTooltip: true,
  //   dataTip: 'Adblue',
  //   dataInfo: '(Total AdblueConsumed / Total FuelConsumed) * 100',
  //   sort: true,
  //   minWidth: '150px',
  // },
  {
    name: 'Engine On',
    id: 'vehRunDuration',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '150px',
  },
  {
    name: 'Total Idling',
    id: 'IdlingDuration',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '150px',
  },
  {
    name: 'Max Speed',
    id: 'MaxSpeed',
    sort: true,
    unitConversion: '(kmph)',
    minWidth: '130px',
  },
  {
    name: 'Mileage',
    id: 'mileage',
    sort: true,
    unitConversion: '(kmpl)',
    minWidth: '100px',
  },
  {
    name: 'L/HR',
    id: 'literPerHour',
    sort: true,
  },
  {
    name: 'Truck Utilization (%)',
    id: 'truckUtilization',
    isTooltip: true,
    dataTip: 'Truck Utilization',
    dataInfo:
      '((VehicleRunDuration + IdlingDuration) / Total hrs selected) * 100',
    sort: true,
  },
];

export const AutoTripSummaryTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  {
    name: 'Trip Start Date',
    id: 'TripStartTime',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
    minWidth: '210px',
  },
  {
    name: 'Trip Start Odometer',
    id: 'TripStartODO',
    sort: true,
    unitConversion: '(kms)',
  },
  { name: 'Trip Start Address', id: 'tripStart', className: 'tripstrat' },
  {
    name: 'Trip End Date',
    id: 'TripEndTime',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
    minWidth: '210px',
  },
  {
    name: 'Trip End Odometer',
    id: 'TripEndODO',
    sort: true,
    unitConversion: '(kms)',
  },
  { name: 'Trip End Address', id: 'tripEnd', className: 'tripstrat' },
  {
    name: 'Distance Travelled',
    id: 'DistanceTravelled',
    sort: true,
    unitConversion: '(kms)',
    minWidth: '170px',
  },
  {
    name: 'Fuel Consumed',
    id: 'TotalFuelConsumed',
    sort: true,
    unitConversion: '(ltrs)',
    minWidth: '150px',
    format: value => (value.includes('.') ? Number(value).toFixed(2) : value),
  },
  // {
  //   name: 'Adblue Consumed',
  //   id: 'AdblueConsumed',
  //   sort: true,
  //   unitConversion: '(ltrs)',
  //   minWidth: '170px',
  // },
  // {
  //   name: 'Adblue (%)',
  //   id: 'adlPercent',
  //   isTooltip: true,
  //   dataTip: 'Adblue',
  //   dataInfo: '(Total AdblueConsumed / Total FuelConsumed) * 100',
  //   sort: true,
  // },
  {
    name: 'Engine On',
    id: 'EngineONhrs',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '130px',
  },
  {
    name: 'Total Idling',
    id: 'IdlingDuration',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '130px',
  },
  {
    name: 'Max Speed',
    id: 'MaxSpeed',
    sort: true,
    unitConversion: '(kmph)',
    minWidth: '130px',
    format: value => (value.includes('.') ? Number(value).toFixed(0) : value),
  },
  {
    name: 'Mileage',
    id: 'FuelKMPL',
    sort: true,
    unitConversion: '(kmpl)',
    minWidth: '100px',
  },
  {
    name: 'L/HR',
    id: 'LitersPerHour',
    sort: true,
  },
  { name: 'Trip', id: 'trip' },
];

export const HubToHubTblHeadName = [
  { name: 'Registration No', id: 'RegistrationNumber', sort: true },
  { name: 'VIN', id: 'Vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  { name: 'Start Hub', id: 'StartHub', sort: true },
  { name: 'Start Hub Tag', id: 'StartHubTag', sort: true },
  {
    name: 'Trip Start Address',
    id: 'tripStart',
    sort: true,
    className: 'tripstrat',
  },
  { name: 'End Hub', id: 'StopHub', sort: true },
  { name: 'End Hub Tag', id: 'EndHubTag', sort: true },
  {
    name: 'Trip End Address',
    id: 'tripEnd',
    sort: true,
    className: 'tripstrat',
  },
  {
    name: 'Trip Start Date',
    id: 'TripStartTime',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
    minWidth: '200px',
  },
  {
    name: 'Trip Start Odometer',
    id: 'TripStartODO',
    sort: true,
    unitConversion: '(kms)',
  },
  {
    name: 'Trip End Date',
    id: 'TripEndTime',
    sort: true,
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
    minWidth: '200px',
  },
  {
    name: 'Trip End Odometer',
    id: 'TripEndODO',
    sort: true,
    unitConversion: '(kms)',
  },
  {
    name: 'Distance Travelled',
    id: 'DistanceTravelled',
    sort: true,
    unitConversion: '(kms)',
    minWidth: '170px',
  },
  {
    name: 'Fuel Consumed',
    id: 'TotalFuelConsumed',
    sort: true,
    unitConversion: '(ltrs)',
    minWidth: '160px',
  },
  // {
  //   name: 'Adblue Consumed',
  //   id: 'AdblueConsumed',
  //   sort: true,
  //   unitConversion: '(ltrs)',
  //   minWidth: '170px',
  // },
  // {
  //   name: 'Adblue (%)',
  //   id: 'adlPercent',
  //   isTooltip: true,
  //   dataTip: 'Adblue',
  //   dataInfo: '(Total AdblueConsumed / Total FuelConsumed) * 100',
  //   sort: true,
  // },
  {
    name: 'Engine On',
    id: 'EngineONhrs',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '130px',
  },
  {
    name: 'Total Idling',
    id: 'IdlingDuration',
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '130px',
  },
  {
    name: 'Max Speed',
    id: 'MaxSpeed',
    sort: true,
    unitConversion: '(kmph)',
    minWidth: '130px',
  },
  {
    name: 'Mileage',
    id: 'FuelKMPL',
    sort: true,
    unitConversion: '(kmpl)',
    minWidth: '100px',
  },
  {
    name: 'L/HR',
    id: 'LitersPerHour',
    sort: true,
  },
  { name: 'Trip', id: 'trip' },
];

export const VehicleInstantTblHeadName = [
  { name: 'Registration No', id: 'registration_id', sort: true },
  { name: 'VIN', id: 'vin', sort: true },
  { name: 'Model', id: 'Model', sort: true },
  {
    name: 'Current Status',
    id: 'status',
    sort: true,
    chip: true,
    minWidth: '130px',
  },
  {
    name: 'Date',
    id: 'Date',
    sort: true,
    minWidth: '130px',
  },
  {
    name: 'Duration Of Current Status',
    id: 'currentStatusDuration',
    sort: true,
    lazyLoad: true,
    convert: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '230px',
  },
  {
    name: 'Odometer Reading',
    id: 'ODO',
    sort: true,
    unitConversion: '(kms)',
    minWidth: '175px',
  },
  { name: 'Engine RPM', id: 'RPM', sort: true },
  {
    name: 'Speed',
    id: 'CSP',
    sort: true,
    unitConversion: '(kmph)',
    minWidth: '100px',
  },
  { name: 'Fuel Reserve (ltrs)', id: 'FGL', sort: true },
  {
    name: 'Adblue Current Level',
    id: 'ADL',
    sort: true,
    unitConversion: '(ltrs)',
    minWidth: '150px',
  },
  { name: 'Geofence Name', id: 'GeofenceName', sort: true },
  { name: 'Location Tag', id: 'locationtag', sort: true },
  { name: 'Address', id: 'Address' },
];

export const ServiceDueTblHeadName = [
  { name: 'Registration No', id: 'registrationId', sort: true },
  { name: 'VIN', id: 'vin', sort: true },
  { name: 'Model', id: 'model', sort: true },
  {
    name: 'Vehicle Age',
    id: 'vehicleAge',
    unitConversion: '(YY:MM:DD)',
    minWidth: '150px',
  },
  {
    name: 'Total Engine On',
    id: 'engineOnDuration',
    unitConversion: '(HH:MM:SS)',
    minWidth: '150px',
  },
  { name: 'Total Distance', id: 'distanceTravelled', unitConversion: '(kms)' },
  {
    name: 'Service Frequency',
    id: 'serviceFrequency',
    unitConversion: '(kms)',
  },
  { name: 'Service Due Soon', id: 'dueSoon' },
];

export const customStatus = {
  RUNNING: 1,
  IDLE: 2,
  STOPPED: 3,
  OFFLINE: 4,
  'No Recent Data': 5,
};
